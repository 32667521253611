.container-form {
    background-color: white;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 5px 5px 10px 1px gray;
    border-radius: 14px;
}

.title-form {
    text-align: center;
    margin-bottom: 13px;
    font-weight: bold;
}

.content-grid{
    margin-right: 50px;
}

.btn-add{
    margin-top: 30px;
}

