.navbar {
    background-color: #026eab;
}

.navbar-light .navbar-nav .nav-link{
    color: white!important;
}

.userIcon{
    color: white;
    margin-top: 10px;
}

.dropdown {
    margin-right: 75px;
}