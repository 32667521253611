.detailBill {
  padding: 20px;
}

.Header{
  padding: 20px;
  -webkit-box-shadow: 2px 12px 8px -10px rgba(0,0,0,0.91); 
  box-shadow: 2px 12px 8px -10px #126cc0;
}

.text-title{
  background-color: #126cc0;
  color: white;
  font-weight: bold;
  width: 40%;
  padding: 5px 5px 5px 20px;
  border-radius: 0px 30px 0px 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.linkGuia{
  color:#126cc0;
  text-decoration: underline;
  cursor: pointer;
}