.account {
  display: block;
}
.printBill {
  display: block;
  text-align: left;
}

.marginBottom{
  margin-bottom: 50px;
}

.negrilla{
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: medium;
  /* text-align: justify; */
  padding: 40px;
}





