.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}

.color {
  color: aliceblue;
}

.adminHeader {
  background-color: #001529;
  height: 64px;
  padding: 10px;
}