.title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.dx-row .dx-data-row .dx-column-lines {
    min-height: 90px;
}

.dx-datagrid-rowsview .dx-row {
    min-height: 90px;
}

.btn {
    margin-left: 5px;
    margin-bottom: 3px;
}