.container-row{
    background: white;
    border-radius: 30px;
    box-shadow: 12px 12px 22px grey;
    margin: 50px 200px;
}

.img-logo {
    padding: 3px;
}

