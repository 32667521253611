* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: white
}

.dx-datagrid-rowsview .dx-row {
    height: 130px!important;
}

