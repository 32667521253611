.enlinea {
    display: inline-block;
    margin-right: 20px;
}
.titulo-campo-guia {
    font-weight: bold;
    font-size: 14px;
}

.datos-contacto {
    padding: 10px;
    font-size: 14px;
}

#element-to-print img {
    height: 80px;
}

#element-to-print{
padding: 5px;
}

.titulo-campo-imagen{
    text-align: center;
}

.fondogris {
    background-color: lightgray;
    text-align: center;
}

td{
    border-width: 1px;
    border-color: "#aaaaaa";
    border-style: solid;
}

.beforeClass{
    margin-bottom: 20px;
    page-break-inside: avoid;
}

.pagebreak{
    margin-bottom: 10px;
}

.img-responsive{
    width: 70%;
}

@page {
    margin: 0.2cm;
    size: A3;
 }

@media print {

    /* .pagebreak {
        clear: both;
        page-break-after: always;
    } */

    .item {
        page-break-after : always;
      }
    
     #item-container {
        display : block;
     }
}
