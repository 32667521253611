.row-content {
    background: white;
    border-radius: 30px;
    box-shadow: 12px 12px 22px grey;
}

img {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

a {
    text-decoration: none;
    color: grey;
}

a:hover {
    text-decoration: none;
    color: hotpink;
}

.recover-password {
    text-decoration: none;
    color: grey;
}

.recover-password:hover {
    cursor: pointer;
    color: blue;
}