.modal_Add_Kit {
  display: block;
}

.deleteIcon{
  color: red;
  cursor: pointer;
  text-align: center;
}

.deleteIcon:hover{
  border: 1px solid red;
}