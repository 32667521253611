.adminFooter {
  background-color: #001529;
  height: 64px;
  padding: 10px;
}

.select {
  width: 200px;
  margin-top: 10px
}

.search {
  width: 200px;
}

.footer {
  background-color: #001529;
  height: 64px;
  padding: 10px;
}

.color-select {
  color: #fff;
}